body>header {
	background-color: #f8f8f8;
	padding-top: 20px;
	padding-bottom: 20px;

	.container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.site-logo {
		display: inline-block;
		margin: 4px auto;
	}

	.social-links {
		display: none;
	}
}

@media screen and (min-width: 640px) {
	body>header {
		background-color: transparent;

		.site-logo {
			display: inline-block;
			margin: 4px auto 4px 0;
		}

		.social-links {
			display: inherit;
		}
	}
}