.social-links{
	display: flex;

	a {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		margin-left: 20px;
		height: 36px;
		width: 36px;
		border-radius: 50%;
		background-color: rgba(255, 255, 255, 0.6);

		svg {
			fill: rgba(0, 0, 0, 0.7);
		}
	}

	a:hover{
		background-color: rgba(255, 255, 255, 0.9);

		svg{
			fill: rgba(0, 0, 0, 1);
		}
	}

	
}