@font-face {
	font-family: 'Muli';
	src: url('../../fonts/Muli-ExtraLight.eot');
	src: local('Muli ExtraLight'), local('Muli-ExtraLight'),
		url('../../fonts/Muli-ExtraLight.eot?#iefix') format('embedded-opentype'),
		url('../../fonts/Muli-ExtraLight.woff2') format('woff2'),
		url('../../fonts/Muli-ExtraLight.woff') format('woff'),
		url('../../fonts/Muli-ExtraLight.ttf') format('truetype'),
		url('../../fonts/Muli-ExtraLight.svg#Muli-ExtraLight') format('svg');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Muli';
	src: url('../../fonts/Muli-Regular.eot');
	src: local('Muli Regular'), local('Muli-Regular'),
		url('../../fonts/Muli-Regular.eot?#iefix') format('embedded-opentype'),
		url('../../fonts/Muli-Regular.woff2') format('woff2'),
		url('../../fonts/Muli-Regular.woff') format('woff'),
		url('../../fonts/Muli-Regular.ttf') format('truetype'),
		url('../../fonts/Muli-Regular.svg#Muli-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Kanit';
	src: url('../../fonts/Kanit-Regular.eot');
	src: local('Kanit Regular'), local('Kanit-Regular'),
		url('../../fonts/Kanit-Regular.eot?#iefix') format('embedded-opentype'),
		url('../../fonts/Kanit-Regular.woff2') format('woff2'),
		url('../../fonts/Kanit-Regular.woff') format('woff'),
		url('../../fonts/Kanit-Regular.ttf') format('truetype'),
		url('../../fonts/Kanit-Regular.svg#Kanit-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Kanit';
	src: url('../../fonts/Kanit-ExtraLight.eot');
	src: local('Kanit ExtraLight'), local('Kanit-ExtraLight'),
		url('../../fonts/Kanit-ExtraLight.eot?#iefix') format('embedded-opentype'),
		url('../../fonts/Kanit-ExtraLight.woff2') format('woff2'),
		url('../../fonts/Kanit-ExtraLight.woff') format('woff'),
		url('../../fonts/Kanit-ExtraLight.ttf') format('truetype'),
		url('../../fonts/Kanit-ExtraLight.svg#Kanit-ExtraLight') format('svg');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}
