.home > header{
	position: static;
}
.home-hero{
	position: static;
	min-height: 100vh;

	.hero-image-small,
	.hero-image-medium{
		@include hidden-lg;
		width: 100%;
		height: auto;
		margin-bottom: 40px;
	}

	.hero-image-small{
		@include hidden-md;
		@include visible-sm;
	}

	.hero-image-medium{
		@include hidden-sm;
		@include visible-md;
	}

	.year{
		display: none;
		font-size: 34px;
		font-weight: 200;
		text-align: center;
		color: $mavens-green-dark;
	}

	h1{
		font-size: 61.2px;
		font-weight: 200;
		text-align: center;
	}
	
	.content{
		margin-top: 40px;
		margin-bottom: 40px;
		text-align: center;
	}

	.cta {
		margin-top: 52px;
	}

	.btn-lg{
		@include btn-lg;
	}
}

@media (min-width: 640px) {

	.home {
		position: relative;
	}

	.home > header {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		width: 100%;
		z-index: 100;
	}

	.home-hero{
		.year{ display: block; }
	}
}

@media (min-width: 960px){
	.home-hero{
		background-image: url("#{$images_path}/brandi-madison-on-red-couch.jpg");
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 100% 0;
		background-attachment: fixed;
		backface-visibility: hidden;
		padding-top: 20vh;

		.year { 
			font-size: 38px;
			text-align: left; 
		}
		
		h1{ 
			font-size: 72px;
			text-align: left; 
		}
		
		.content{
			max-width: 600px;
			margin-left: 0;
			margin-right: auto;
			text-align: left;
		}
	}
}

#newsletter-signup-modal{
	@include modal;

	.modal-header button{
		display: inline-block;	
		height: 34px;
		width: 34px;
		background-color: lightgray;
		border: none;
		border-radius: 50%;
		position: absolute;
		right: 20px;
		top: 53px;
	}

	iframe{
		background: white;
	}
}

// TWO TILE SECTION
.home .tile-wrapper{
	display:flex;
	flex-direction: column;
	margin: 0;
	padding: 0;
}

.home .tile{
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 40px 25px;

	img{ width:50px; }

	.content{
		flex: 1;
		padding-left: 25px;
	}

	h3{
		color: white;
		font-weight: 200;
	}


	p{ color: rgba(255, 255, 255, 0.7); }

	a{
		@include link-right-arrow-white;
		font-weight: 100;
		color:white;
		margin-top: 1.5em;
	}


	&:first-child{ background-color: $mavens-green-dark; }

	&:last-child{ background-color: $mavens-tan; }
}

@media (min-width: 960px){
	.home .tile-wrapper {
		flex-direction: row;
	}
	.home .tile{
		width:50%;
		padding: 65px 40px;
	}
}

// ABOUT SECTION
.home-about{
	padding: 70px 0;
	background-color: #f8f8f8;

	h2{
		font-size: 2.6rem;
		line-height: 1.2;
		font-weight: 100;
	}

	p{
		font-size: 23px;
		font-weight: 300;
	}

	a{
		@include link-right-arrow-black;
		font-size: 1rem;
		color: $color-text;
	}
}

@media (min-width: 960px){
	.home-about{
		background-image: url("#{$images_path}/background-about.svg");
		background-position-y: calc(50% + 30.1px);
		background-position-x: 33%;
		background-repeat: no-repeat;
		padding: 140px 0;
	}
}

// FOUNDERS SECTION
.home-founders{

	.container{
		display: flex;
		flex-direction: column;
	}

	.founder{
		display:flex;
		flex-direction: column;
		padding: 40px 25px;
	}

	.content{
		text-align: center;
	}

	h3{
		margin-bottom: 0;
	}

	.founder-title{
		font-size: 0.875rem;
		line-height: 1.4;
		color: #727272;
		margin-bottom: 2rem;
	}

	a{
		@include link-right-arrow-black;
		display: inline-block;
		color: $color-text;
		margin-top: 1.5em;
	}

	img{
		margin-top: 65px;
	}

	hr.so-fancy{
		position: relative;
		height: 20px;
		margin-top: 65px;
		background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22none%22%20stroke%3D%22%23eee%22%20stroke-width%3D%222%22%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%227%22%20%2F%3E%0A%3C%2Fsvg%3E%0A);
		background-repeat: no-repeat;
		background-position: 50% 50%;
		border: 0;


		&::before,
		&::after{
			width: 100%;
			content: "";
			position: absolute;
			top: 50%;
			max-width: calc(50% - (50px / 2));
			border-bottom: 1px solid #eee;
		}

		&::before{
			right: calc(50% + (50px / 2));
		}

		&::after{
			left: calc(50% + (50px / 2));
		}
	}
}

@media (min-width: 960px){
	.home-founders{
		padding-bottom: 40px;
		
		.founder{
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			padding: 0;
		}

		.founder:last-child{
			flex-direction: row-reverse;
		}

		.founder > * {
			width:50%;
		}

		.content{
			padding: 70px 40px;
			text-align: left;
		}

		img{
			margin-top: 0;
		}

		hr.so-fancy{
			display: none;

			&::before,
			&::after{
				display: none;
			}
		}
	}
}