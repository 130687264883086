.home > footer{
	padding: 20px 0;
	background-color: $mavens-green;
	color: rgba(255, 255, 255, 0.6);

	.container{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.social-links a{
		opacity: 0.6;

		&:hover{
			opacity: 0.8;
		}
		
	}
}
