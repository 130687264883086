@mixin svg-fill($src, $color-default, $color-new, $property: background-image) {
	// Vars
	$escape-color-default: escape($color-default);
	$escape-color-new: escape("#{$color-new}");

	$data-uri: data-uri('image/svg+xml;charset=UTF-8', "#{$src}");
	$replace-src: replace("#{$data-uri}", "#{$escape-color-default}", "#{$escape-color-new}", "g");

	#{$property}: e($replace-src);
}

@mixin hidden-sm{
	@media (max-width:640px){
		display: none;
	}
}

@mixin hidden-md {
	@media (min-width:641px) and (max-width:960px) {
		display: none;
	}
}

@mixin hidden-lg {
	@media (min-width:961px) {
		display: none;
	}
}

@mixin visible-sm {
	@media (max-width:640px) {
		display: static;
	}
}

@mixin visible-md {
	@media (min-width:641px) and (max-width:960px) {
		display: static;
	}
}

@mixin visible-lg {
	@media (min-width:961px) {
		display: static;
	}
}

@mixin btn {
	display: inline-block;
	margin: 0;
	overflow: visible;
	text-transform: none;
	-webkit-appearance: none;
	border-radius: $border-radius-default;
	vertical-align: middle;
	padding: 0 25px;
	font-size: 1.05rem;
	line-height: 41px;
	font-weight: 300;
	text-align: center;
	background-color: transparent;
	color: $color-text;
	border: 2px solid $color-text;
	background-image: linear-gradient(90deg, $mavens-green 50%, transparent 50%);
	background-size: 200%;
	background-position-x: 100%;
	transition: .17s ease-in-out;
	transition-property: color, background-color, background-position, border-color, box-shadow;

	&:hover,
	&:focus{
		color: white;
		border-color: $mavens-green;
		background-position-x: 0;
		text-decoration: none;
	}

	&:not(:disabled){
		cursor: pointer;
	}
}

@mixin btn-lg{
	@include btn;

	padding: 0 40px;
	line-height: 56px;
	font-size: 1.05rem;
}

@mixin modal{
	// The background
	position:fixed;
	top:0;
	right:0;
	bottom:0;
	left:0;
	z-index:1000;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	background: rgba(0,0,0,0.6);
	transition: opacity .15s linear;
	// The contents
	

	&.is-closed{
		display:none;
	}
	&.is-open{
		display:flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-end;
	}

	.modal-header{
		display: block;
		background:white;
		width: 100%;
		padding: 15px 40px;
		margin: 0;
	}

	.modal-content{
		display: block;
		max-width: 960px;
		margin:0;
		background: white;
	}
}

@mixin animate-link-underline{
	display: inline-block;
	text-decoration: none;
	position: relative;
	transition: 0.1s ease-in-out;
	transition-property: color, background-color, border-color;

	&::before{
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		right: 100%;
		border-bottom: 1px solid currentcolor;
		transition: right 0.3s ease-out;
	}
	
	&:hover::before{
		right:0;
	}
}

@mixin link-right-arrow-white{
	position:relative;
	text-decoration: none;

	&::before {
		// Right arrow
		content: "";
		display:block;
		background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2223%22%20height%3D%2211%22%20viewBox%3D%220%200%2023%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22rgba%28255,%20255,%20255,%200.5%29%22%20points%3D%2217%201%2022%205.5%2017%2010%20%22%20%2F%3E%0A%20%20%20%20%3Cline%20fill%3D%22none%22%20stroke%3D%22rgba%28255,%20255,%20255,%200.5%29%22%20x1%3D%220%22%20y1%3D%225.5%22%20x2%3D%2222.4%22%20y2%3D%225.5%22%20%2F%3E%0A%3C%2Fsvg%3E);
		position: absolute;
		top: 0;
		bottom: 0;
		right: -29px;
		width: 23px;
		background-repeat: no-repeat;
		background-position: calc(100% - 5px) 50%;
		transition: background-position .2s ease-out;
	}

	&:hover::before{
		background-position: 100% 50%;
	}

	&:hover{
		font-weight: bolder;
	}
}

@mixin link-right-arrow-black {
	@include link-right-arrow-white();
	&::before{
		background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2223%22%20height%3D%2211%22%20viewBox%3D%220%200%2023%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%23494949%22%20points%3D%2217%201%2022%205.5%2017%2010%20%22%20%2F%3E%0A%20%20%20%20%3Cline%20fill%3D%22none%22%20stroke%3D%22%23494949%22%20x1%3D%220%22%20y1%3D%225.5%22%20x2%3D%2222.4%22%20y2%3D%225.5%22%20%2F%3E%0A%3C%2Fsvg%3E);
	}
}