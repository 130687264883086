/*
 * Force vertical scrollbar
 * 1. `overflow-x` has to be on the `body` element for Safari to prevent horizontal scrolling on touch
      This causes a random bug showing a vertical scrollbar in combination with some fonts like `Poppins`
      This is why `overflow-y` must also be set to `hidden`
 */
html {
	overflow-y: scroll;
}

/* 1 */
body {
	overflow: hidden;
}

// IMPORTS FROM MVP.CSS
html {
	scroll-behavior: smooth;
}

@media (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}

body{
	color: $color-text;
	font-family: $global-primary-font-family, $sans-fallback-font-family;
	line-height: 1.58;
	overflow-x:hidden;
	margin: 0;
	padding: 0;
}

img{
	max-width: 100%;
}

pre{
	overflow: auto;
}

// END MVP CSS


::selection{
	background: $mavens-green;
	color: #fff;
	text-shadow: none;
}

// HEADINGS
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6{
	margin: 0 0 20px 0;
	font-weight: normal;
	color: #333;
	text-transform: none;
}

h1, .h1{
	font-size: 39.1px;
	line-height: 1.2;
	font-weight: 200;
}

h2, .h2{
	font-size: 34.2px;
	line-height: 1.3;
	font-weight: 200;
}

h3, .h3{
	font-size: 32px;
	line-height: 1.4;
	font-weight: 200;
}

h4, .h4{
	font-size: 26px;
	line-height: 1.4;
	font-weight: 300;
}

h5, .h5{
	font-size: 20px;
	line-height: 1.4;
	font-weight: 300;
}

h6, .h6{
	font-size: 17px;
	line-height: 1.4;
	font-weight: 300;
}

@media (min-width: 960px){
	h1, .h1{ font-size: 46px; }
	h2, .h2{ font-size: 38px; }
}

.container{
	display: flow-root;
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 15px;
}

.container .container{
	padding-left: 0;
	padding-right: 0;
}

.container-fluid{
	max-width: none;
}

@media (min-width: 640px){
	.container{ padding: 0 30px; }
}

@media (min-width: 960px){
	.container { padding: 0 40px; }
}