@font-face {
  font-family: "Muli";
  src: url("../../fonts/Muli-ExtraLight.eot");
  src: local("Muli ExtraLight"), local("Muli-ExtraLight"), url("../../fonts/Muli-ExtraLight.eot?#iefix") format("embedded-opentype"), url("../../fonts/Muli-ExtraLight.woff2") format("woff2"), url("../../fonts/Muli-ExtraLight.woff") format("woff"), url("../../fonts/Muli-ExtraLight.ttf") format("truetype"), url("../../fonts/Muli-ExtraLight.svg#Muli-ExtraLight") format("svg");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Muli";
  src: url("../../fonts/Muli-Regular.eot");
  src: local("Muli Regular"), local("Muli-Regular"), url("../../fonts/Muli-Regular.eot?#iefix") format("embedded-opentype"), url("../../fonts/Muli-Regular.woff2") format("woff2"), url("../../fonts/Muli-Regular.woff") format("woff"), url("../../fonts/Muli-Regular.ttf") format("truetype"), url("../../fonts/Muli-Regular.svg#Muli-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Kanit";
  src: url("../../fonts/Kanit-Regular.eot");
  src: local("Kanit Regular"), local("Kanit-Regular"), url("../../fonts/Kanit-Regular.eot?#iefix") format("embedded-opentype"), url("../../fonts/Kanit-Regular.woff2") format("woff2"), url("../../fonts/Kanit-Regular.woff") format("woff"), url("../../fonts/Kanit-Regular.ttf") format("truetype"), url("../../fonts/Kanit-Regular.svg#Kanit-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Kanit";
  src: url("../../fonts/Kanit-ExtraLight.eot");
  src: local("Kanit ExtraLight"), local("Kanit-ExtraLight"), url("../../fonts/Kanit-ExtraLight.eot?#iefix") format("embedded-opentype"), url("../../fonts/Kanit-ExtraLight.woff2") format("woff2"), url("../../fonts/Kanit-ExtraLight.woff") format("woff"), url("../../fonts/Kanit-ExtraLight.ttf") format("truetype"), url("../../fonts/Kanit-ExtraLight.svg#Kanit-ExtraLight") format("svg");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
/*
 * Force vertical scrollbar
 * 1. `overflow-x` has to be on the `body` element for Safari to prevent horizontal scrolling on touch
      This causes a random bug showing a vertical scrollbar in combination with some fonts like `Poppins`
      This is why `overflow-y` must also be set to `hidden`
 */
html {
  overflow-y: scroll;
}

/* 1 */
body {
  overflow: hidden;
}

html {
  scroll-behavior: smooth;
}

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}
body {
  color: #242424;
  font-family: Muli, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  line-height: 1.58;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

pre {
  overflow: auto;
}

::selection {
  background: #32615b;
  color: #fff;
  text-shadow: none;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin: 0 0 20px 0;
  font-weight: normal;
  color: #333;
  text-transform: none;
}

h1, .h1 {
  font-size: 39.1px;
  line-height: 1.2;
  font-weight: 200;
}

h2, .h2 {
  font-size: 34.2px;
  line-height: 1.3;
  font-weight: 200;
}

h3, .h3 {
  font-size: 32px;
  line-height: 1.4;
  font-weight: 200;
}

h4, .h4 {
  font-size: 26px;
  line-height: 1.4;
  font-weight: 300;
}

h5, .h5 {
  font-size: 20px;
  line-height: 1.4;
  font-weight: 300;
}

h6, .h6 {
  font-size: 17px;
  line-height: 1.4;
  font-weight: 300;
}

@media (min-width: 960px) {
  h1, .h1 {
    font-size: 46px;
  }
  h2, .h2 {
    font-size: 38px;
  }
}
.container {
  display: flow-root;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.container .container {
  padding-left: 0;
  padding-right: 0;
}

.container-fluid {
  max-width: none;
}

@media (min-width: 640px) {
  .container {
    padding: 0 30px;
  }
}
@media (min-width: 960px) {
  .container {
    padding: 0 40px;
  }
}
.home > header {
  position: static;
}

.home-hero {
  position: static;
  min-height: 100vh;
}
.home-hero .hero-image-small,
.home-hero .hero-image-medium {
  width: 100%;
  height: auto;
  margin-bottom: 40px;
}
@media (min-width: 961px) {
  .home-hero .hero-image-small,
  .home-hero .hero-image-medium {
    display: none;
  }
}
@media (min-width: 641px) and (max-width: 960px) {
  .home-hero .hero-image-small {
    display: none;
  }
}
@media (max-width: 640px) {
  .home-hero .hero-image-small {
    display: static;
  }
}
@media (max-width: 640px) {
  .home-hero .hero-image-medium {
    display: none;
  }
}
@media (min-width: 641px) and (max-width: 960px) {
  .home-hero .hero-image-medium {
    display: static;
  }
}
.home-hero .year {
  display: none;
  font-size: 34px;
  font-weight: 200;
  text-align: center;
  color: #25443F;
}
.home-hero h1 {
  font-size: 61.2px;
  font-weight: 200;
  text-align: center;
}
.home-hero .content {
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
}
.home-hero .cta {
  margin-top: 52px;
}
.home-hero .btn-lg {
  display: inline-block;
  margin: 0;
  overflow: visible;
  text-transform: none;
  -webkit-appearance: none;
  border-radius: 0;
  vertical-align: middle;
  padding: 0 25px;
  font-size: 1.05rem;
  line-height: 41px;
  font-weight: 300;
  text-align: center;
  background-color: transparent;
  color: #242424;
  border: 2px solid #242424;
  background-image: linear-gradient(90deg, #32615b 50%, transparent 50%);
  background-size: 200%;
  background-position-x: 100%;
  transition: 0.17s ease-in-out;
  transition-property: color, background-color, background-position, border-color, box-shadow;
  padding: 0 40px;
  line-height: 56px;
  font-size: 1.05rem;
}
.home-hero .btn-lg:hover, .home-hero .btn-lg:focus {
  color: white;
  border-color: #32615b;
  background-position-x: 0;
  text-decoration: none;
}
.home-hero .btn-lg:not(:disabled) {
  cursor: pointer;
}

@media (min-width: 640px) {
  .home {
    position: relative;
  }
  .home > header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 100;
  }
  .home-hero .year {
    display: block;
  }
}
@media (min-width: 960px) {
  .home-hero {
    background-image: url("/wp-content/themes/mavens/images/dist/brandi-madison-on-red-couch.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% 0;
    background-attachment: fixed;
    backface-visibility: hidden;
    padding-top: 20vh;
  }
  .home-hero .year {
    font-size: 38px;
    text-align: left;
  }
  .home-hero h1 {
    font-size: 72px;
    text-align: left;
  }
  .home-hero .content {
    max-width: 600px;
    margin-left: 0;
    margin-right: auto;
    text-align: left;
  }
}
#newsletter-signup-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: rgba(0, 0, 0, 0.6);
  transition: opacity 0.15s linear;
}
#newsletter-signup-modal.is-closed {
  display: none;
}
#newsletter-signup-modal.is-open {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
#newsletter-signup-modal .modal-header {
  display: block;
  background: white;
  width: 100%;
  padding: 15px 40px;
  margin: 0;
}
#newsletter-signup-modal .modal-content {
  display: block;
  max-width: 960px;
  margin: 0;
  background: white;
}
#newsletter-signup-modal .modal-header button {
  display: inline-block;
  height: 34px;
  width: 34px;
  background-color: lightgray;
  border: none;
  border-radius: 50%;
  position: absolute;
  right: 20px;
  top: 53px;
}
#newsletter-signup-modal iframe {
  background: white;
}

.home .tile-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.home .tile {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 40px 25px;
}
.home .tile img {
  width: 50px;
}
.home .tile .content {
  flex: 1;
  padding-left: 25px;
}
.home .tile h3 {
  color: white;
  font-weight: 200;
}
.home .tile p {
  color: rgba(255, 255, 255, 0.7);
}
.home .tile a {
  position: relative;
  text-decoration: none;
  font-weight: 100;
  color: white;
  margin-top: 1.5em;
}
.home .tile a::before {
  content: "";
  display: block;
  background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2223%22%20height%3D%2211%22%20viewBox%3D%220%200%2023%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22rgba%28255,%20255,%20255,%200.5%29%22%20points%3D%2217%201%2022%205.5%2017%2010%20%22%20%2F%3E%0A%20%20%20%20%3Cline%20fill%3D%22none%22%20stroke%3D%22rgba%28255,%20255,%20255,%200.5%29%22%20x1%3D%220%22%20y1%3D%225.5%22%20x2%3D%2222.4%22%20y2%3D%225.5%22%20%2F%3E%0A%3C%2Fsvg%3E);
  position: absolute;
  top: 0;
  bottom: 0;
  right: -29px;
  width: 23px;
  background-repeat: no-repeat;
  background-position: calc(100% - 5px) 50%;
  transition: background-position 0.2s ease-out;
}
.home .tile a:hover::before {
  background-position: 100% 50%;
}
.home .tile a:hover {
  font-weight: bolder;
}
.home .tile:first-child {
  background-color: #25443F;
}
.home .tile:last-child {
  background-color: #bb9b84;
}

@media (min-width: 960px) {
  .home .tile-wrapper {
    flex-direction: row;
  }
  .home .tile {
    width: 50%;
    padding: 65px 40px;
  }
}
.home-about {
  padding: 70px 0;
  background-color: #f8f8f8;
}
.home-about h2 {
  font-size: 2.6rem;
  line-height: 1.2;
  font-weight: 100;
}
.home-about p {
  font-size: 23px;
  font-weight: 300;
}
.home-about a {
  position: relative;
  text-decoration: none;
  font-size: 1rem;
  color: #242424;
}
.home-about a::before {
  content: "";
  display: block;
  background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2223%22%20height%3D%2211%22%20viewBox%3D%220%200%2023%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22rgba%28255,%20255,%20255,%200.5%29%22%20points%3D%2217%201%2022%205.5%2017%2010%20%22%20%2F%3E%0A%20%20%20%20%3Cline%20fill%3D%22none%22%20stroke%3D%22rgba%28255,%20255,%20255,%200.5%29%22%20x1%3D%220%22%20y1%3D%225.5%22%20x2%3D%2222.4%22%20y2%3D%225.5%22%20%2F%3E%0A%3C%2Fsvg%3E);
  position: absolute;
  top: 0;
  bottom: 0;
  right: -29px;
  width: 23px;
  background-repeat: no-repeat;
  background-position: calc(100% - 5px) 50%;
  transition: background-position 0.2s ease-out;
}
.home-about a:hover::before {
  background-position: 100% 50%;
}
.home-about a:hover {
  font-weight: bolder;
}
.home-about a::before {
  background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2223%22%20height%3D%2211%22%20viewBox%3D%220%200%2023%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%23494949%22%20points%3D%2217%201%2022%205.5%2017%2010%20%22%20%2F%3E%0A%20%20%20%20%3Cline%20fill%3D%22none%22%20stroke%3D%22%23494949%22%20x1%3D%220%22%20y1%3D%225.5%22%20x2%3D%2222.4%22%20y2%3D%225.5%22%20%2F%3E%0A%3C%2Fsvg%3E);
}

@media (min-width: 960px) {
  .home-about {
    background-image: url("/wp-content/themes/mavens/images/dist/background-about.svg");
    background-position-y: calc(50% + 30.1px);
    background-position-x: 33%;
    background-repeat: no-repeat;
    padding: 140px 0;
  }
}
.home-founders .container {
  display: flex;
  flex-direction: column;
}
.home-founders .founder {
  display: flex;
  flex-direction: column;
  padding: 40px 25px;
}
.home-founders .content {
  text-align: center;
}
.home-founders h3 {
  margin-bottom: 0;
}
.home-founders .founder-title {
  font-size: 0.875rem;
  line-height: 1.4;
  color: #727272;
  margin-bottom: 2rem;
}
.home-founders a {
  position: relative;
  text-decoration: none;
  display: inline-block;
  color: #242424;
  margin-top: 1.5em;
}
.home-founders a::before {
  content: "";
  display: block;
  background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2223%22%20height%3D%2211%22%20viewBox%3D%220%200%2023%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22rgba%28255,%20255,%20255,%200.5%29%22%20points%3D%2217%201%2022%205.5%2017%2010%20%22%20%2F%3E%0A%20%20%20%20%3Cline%20fill%3D%22none%22%20stroke%3D%22rgba%28255,%20255,%20255,%200.5%29%22%20x1%3D%220%22%20y1%3D%225.5%22%20x2%3D%2222.4%22%20y2%3D%225.5%22%20%2F%3E%0A%3C%2Fsvg%3E);
  position: absolute;
  top: 0;
  bottom: 0;
  right: -29px;
  width: 23px;
  background-repeat: no-repeat;
  background-position: calc(100% - 5px) 50%;
  transition: background-position 0.2s ease-out;
}
.home-founders a:hover::before {
  background-position: 100% 50%;
}
.home-founders a:hover {
  font-weight: bolder;
}
.home-founders a::before {
  background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2223%22%20height%3D%2211%22%20viewBox%3D%220%200%2023%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%23494949%22%20points%3D%2217%201%2022%205.5%2017%2010%20%22%20%2F%3E%0A%20%20%20%20%3Cline%20fill%3D%22none%22%20stroke%3D%22%23494949%22%20x1%3D%220%22%20y1%3D%225.5%22%20x2%3D%2222.4%22%20y2%3D%225.5%22%20%2F%3E%0A%3C%2Fsvg%3E);
}
.home-founders img {
  margin-top: 65px;
}
.home-founders hr.so-fancy {
  position: relative;
  height: 20px;
  margin-top: 65px;
  background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22none%22%20stroke%3D%22%23eee%22%20stroke-width%3D%222%22%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%227%22%20%2F%3E%0A%3C%2Fsvg%3E%0A);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border: 0;
}
.home-founders hr.so-fancy::before, .home-founders hr.so-fancy::after {
  width: 100%;
  content: "";
  position: absolute;
  top: 50%;
  max-width: calc(50% - 25px);
  border-bottom: 1px solid #eee;
}
.home-founders hr.so-fancy::before {
  right: calc(50% + 25px);
}
.home-founders hr.so-fancy::after {
  left: calc(50% + 25px);
}

@media (min-width: 960px) {
  .home-founders {
    padding-bottom: 40px;
  }
  .home-founders .founder {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
  }
  .home-founders .founder:last-child {
    flex-direction: row-reverse;
  }
  .home-founders .founder > * {
    width: 50%;
  }
  .home-founders .content {
    padding: 70px 40px;
    text-align: left;
  }
  .home-founders img {
    margin-top: 0;
  }
  .home-founders hr.so-fancy {
    display: none;
  }
  .home-founders hr.so-fancy::before, .home-founders hr.so-fancy::after {
    display: none;
  }
}
.social-links {
  display: flex;
}
.social-links a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.6);
}
.social-links a svg {
  fill: rgba(0, 0, 0, 0.7);
}
.social-links a:hover {
  background-color: rgba(255, 255, 255, 0.9);
}
.social-links a:hover svg {
  fill: rgb(0, 0, 0);
}

body > header {
  background-color: #f8f8f8;
  padding-top: 20px;
  padding-bottom: 20px;
}
body > header .container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
body > header .site-logo {
  display: inline-block;
  margin: 4px auto;
}
body > header .social-links {
  display: none;
}

@media screen and (min-width: 640px) {
  body > header {
    background-color: transparent;
  }
  body > header .site-logo {
    display: inline-block;
    margin: 4px auto 4px 0;
  }
  body > header .social-links {
    display: inherit;
  }
}
.home > footer {
  padding: 20px 0;
  background-color: #32615b;
  color: rgba(255, 255, 255, 0.6);
}
.home > footer .container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.home > footer .social-links a {
  opacity: 0.6;
}
.home > footer .social-links a:hover {
  opacity: 0.8;
}