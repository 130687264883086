// @import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');


$images_path: 							"/wp-content/themes/mavens/images/dist";

$border-radius-default:					0;

// Fonts
$sans-fallback-font-family:				-apple-system,
			BlinkMacSystemFont,
			"Segoe UI",
			Roboto,
			Oxygen-Sans,
			Ubuntu,
			Cantarell,
			"Helvetica Neue",
			sans-serif;

$global-primary-font-family:			Muli;
$global-primary-font-weight: 			400;
$global-primary-text-transform: 		none;
$global-primary-letter-spacing: 		normal;
$global-primary-font-style: 			normal;

$global-secondary-font-family: 			Kanit;
$global-secondary-font-weight: 			200;
$global-secondary-text-transform: 		uppercase;
$global-secondary-letter-spacing:		3px;
$global-secondary-font-style: 			normal;

$global-tertiary-font-family: 			inherit;
$global-tertiary-font-weight: 			inherit;

// Colors
$mavens-green-light: 	#B5C3C2;
$mavens-green: 			#32615b;
$mavens-green-dark:		#25443F;
$mavens-tan:			#bb9b84;

$color-text: 			#242424;